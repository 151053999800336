import { useContext, useState } from 'react'

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Divider,
} from '@mui/material'
import { NavLink as RouterLink, useNavigate } from 'react-router-dom'
import BusinessIcon from '@mui/icons-material/Business';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import MoneyIcon from '@mui/icons-material/Money';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RestoreIcon from '@mui/icons-material/Restore'
import { SidebarContext } from '../../../../contexts/SidebarContext'
import LogoutTwoTone from '@mui/icons-material/LogoutTwoTone'
import LoginTwoTone from '@mui/icons-material/LoginTwoTone'
import PeopleIcon from '@mui/icons-material/People'
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone'
import ViewListIcon from '@mui/icons-material/ViewList'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import StoreIcon from '@mui/icons-material/Store'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import SettingsIcon from '@mui/icons-material/Settings'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import LogoutIcon from '@mui/icons-material/Logout'
import ReceiptIcon from '@mui/icons-material/Receipt'
import SummarizeIcon from '@mui/icons-material/Summarize'
import AssessmentIcon from '@mui/icons-material/Assessment'
import hotkeys from 'hotkeys-js';
import { removeToken } from '../../../../utils'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import ApplicationLinks from '../../../../ApplicationLinks'
import { Bill, Customer } from '../../../../../src/services'
import SubmitItem from '../../../../../src/content/applications/CurrentAccounts/SubmitItem'
import { SiteDto } from '../../../../../src/components/SitesAutoComplete/Services'
import { CreateMonthlyBill } from '../../../../../src/content/applications/MonthlyBills/CreateMonthlyBill'
import { AddEditPaymentItem } from '../../../../../src/content/applications/slips/AddEditPayment'
import OtherCharges from '../../../../../src/content/applications/CurrentAccounts/OtherCharges'
import { AddAccountItem } from '../../../../../src/content/applications/CurrentAccounts/AddNewAccountItem'
import IssueMoreItem from '../../../../../src/content/applications/CurrentAccounts/IssueMoreItem'
import AddCustomer from '../../../../../src/content/applications/Customers/AddCustomer'

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.black[100]};
      padding: ${theme.spacing(0, 0.5)};
      line-height: 1.4;
    }
`
)

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 0px 0;
        font-weight: 300;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.dark};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: 300;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          font-weight: 400;

          color: ${theme.colors.alpha.black[80]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(0.6, 1.5)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};
            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.black[80]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.black[80]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }
          
          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.black[100], 0.06)};
            color: ${theme.colors.alpha.black[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.black[100]};
            }
          }
          &.Mui-focusVisible {
            background-color: ${alpha(theme.colors.alpha.black[100], 0.06)};
            color: ${theme.colors.alpha.black[100]};
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.black[100]};
            }
            border: 1px dotted;
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.black[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity',
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }
              &.active,
              &:hover {
                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
)


function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext)
  const { data } = useAppSelector((state) => state.userData)
  const navigate = useNavigate()
  const itemHeight = 34
  const dispatchAction = useAppDispatch()
  const handleLogout = (): void => {
    closeSidebar()
    removeToken()
    dispatchAction({ type: 'CLEAR_ALL_STORE_DATA' })
    navigate('/login')
  }
  const [showAddCustomer, setShowAddCustomer] = useState<boolean>(false)
  const [newAccount, setNewAccount] = useState<Bill | null>(null)
  const [openNewSite, setOpenNewSite] = useState<boolean>(false)
  const [createOutwardChallan, setCreateOutwardChallan] = useState<boolean>(false)
  const [createInwardChallan, setCreateInwardChallan] = useState<boolean>(false)
  const [showAddPayment, setShowAddPayment] = useState<boolean>(false)
  const [showReceiveLost, setReceiveLost] = useState<boolean>(false)
  const [showOtherCharge, setShowOtherCharge] = useState<boolean>(false)
  const [showCreateMonthlyBill, setShowCreateMonthlyBill] = useState<boolean>(false)
  const [newCustomer, setNewCustomer] = useState<Customer>(null)

  hotkeys.filter = function (event) {
    // Allow all keys including function keys (like F1)
    return true;
  };
  hotkeys('alt+f2', function(event, handler){
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    navigate("/dashboard/current-account")
  });  

  hotkeys('alt+f3', function(event, handler){
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    navigate("/management/customers")
  });

  hotkeys('alt+f4', function(event, handler){
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    navigate("/more/all-challans/OUT")
  });

  hotkeys('alt+f5', function(event, handler){
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    navigate("/more/all-challans/IN")
  });

  hotkeys('alt+f10', function(event, handler){
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    navigate("/reports/all-bills")
  });

  
  hotkeys('alt+d', function(event, handler){
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    navigate("/slips/by-date/DATE")
  });
  hotkeys('alt+p', function(event, handler){
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    navigate("/management/profile/details")
  });

  hotkeys('alt+s', function(event, handler){
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    navigate("/management/profile/settings")
  });
  hotkeys('alt+r', function(event, handler){
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    navigate("/charts/billing-chart")
  });

  hotkeys('alt+k', function(event, handler){
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    navigate("/stock/item-category")
  });

  hotkeys('alt+b', function(event, handler){
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    navigate("/charts/sites-stock-summary")
  });

  hotkeys('f2', function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    setOpenNewSite(true)
  });
  hotkeys('f3', function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    setShowAddCustomer(true)
  });
  hotkeys('f4', function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    setCreateOutwardChallan(true)
  });
  hotkeys('f5', function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    setCreateInwardChallan(true)
  });
  hotkeys('f6', function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    setReceiveLost(true)
  });

  hotkeys('f8', function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    setShowAddPayment(true)
  });
  
  hotkeys('f9', function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    setShowOtherCharge(true)
  });
  hotkeys('f10', function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    setShowCreateMonthlyBill(true)
  });
  return (
    <>
      <MenuWrapper>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
             Sites & Parties
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
            <ListItem component="div"  >
                <Button
                 
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.currentAccountLink()}
                  startIcon={<ViewListIcon />}
                  aria-label="Accounts"
                >
                  Sites (Alt+F2)
                </Button>
              </ListItem>

              <ListItem component="div"  >
                <Button
                 
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.customerLink()}
                  startIcon={<PeopleIcon />}
                  aria-label="Cutomers"
                >
                  Parties (Alt+F3)
                </Button>
              </ListItem>
              <ListItem component="div"  >
                <Button
                   
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.allChallansLink('OUT')}
                  startIcon={<LogoutTwoTone />}
                  aria-label="All Out Challans"
                >
                  Out Challans (Alt+F4)
                </Button>
              </ListItem>
              <ListItem component="div"  >
                <Button
                  
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.allChallansLink('IN')}
                  startIcon={<LoginTwoTone />}
                  aria-label="All In Challans"
                >
                  In Challans (Alt+F5)
                </Button>
              </ListItem>
              <ListItem component="div"  >
                <Button
                 
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.allBillsLink()}
                  startIcon={<ReceiptIcon />}
                  aria-label="All Bills"
                >
                  All Invoices (Alt+F10)
                </Button>
              </ListItem>
              <ListItem  component="div">
                <Button
                 
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.slipsByDateLink('DATE')}
                  startIcon={<CalendarMonthIcon />}
                  aria-label="Find by Date"
                >
                  Find by Date(Alt+D)
                </Button>
              </ListItem>
              <Divider sx={{mt:1.5}}/>
              <ListItem component="div"  >
                <Button
                  
                  onClick={() => {
                    closeSidebar()
                    setOpenNewSite(true)
                  }}
                  startIcon={<DomainAddIcon />}
                  aria-label="Add Site"
                >
                 Add Site (F2)
                </Button>
              </ListItem>
              <ListItem component="div"  >
                <Button
                  
                  onClick={() => {
                    closeSidebar()
                    setOpenNewSite(true)
                  }}
                  startIcon={<PersonAddIcon />}
                  aria-label="Add Party"
                >
                 Add Party (F3)
                </Button>
              </ListItem>
              <ListItem component="div"  >
                <Button
                  
                  onClick={() => {
                    closeSidebar()
                    setCreateOutwardChallan(true)
                  }}
                  startIcon={<PostAddIcon />}
                  aria-label="Outward Challan"
                >
                 Outward Challan (F4)
                </Button>
              </ListItem>

              <ListItem component="div"  >
                <Button
                   
                  onClick={() => {
                    closeSidebar()
                    setCreateInwardChallan(true)
                  }}
                  startIcon={<PostAddIcon />}
                  aria-label="Inward Challan"
                >
                  Inward Challan (F5)
                </Button>
              </ListItem>
              
              <ListItem  component="div">
                <Button
                 
                  disableRipple
                  onClick={() => {
                    closeSidebar()
                    setReceiveLost(true)
                  }}
                  startIcon={<BrokenImageIcon />}
                  aria-label="Lost/Damage"
                >
                  Lost/Damage (F6)
                </Button>
              </ListItem>
              <ListItem  component="div">
                <Button
                 
                  disableRipple
                  onClick={() => {
                    closeSidebar()
                    setReceiveLost(true)
                  }}
                  startIcon={<MoneyIcon />}
                  aria-label="Receive Payment"
                >
                  Receive Payment (F8)
                </Button>
              </ListItem>
              <ListItem component="div"  >
                <Button
                 
                  disableRipple
                  onClick={() => {
                    closeSidebar()
                    setShowOtherCharge(true)
                  }}
                  startIcon={<CurrencyRupeeIcon />}
                  aria-label="Other Charges"
                >
                  Other Charges (F9)
                </Button>
              </ListItem>
              <ListItem component="div"  >
                <Button
                   
                  disableRipple
                  onClick={() => {
                    closeSidebar()
                    setShowCreateMonthlyBill(true)
                  }}
                  startIcon={<LogoutTwoTone />}
                  aria-label="Create Invoice"
                >
                  Create Invoice (F10)
                </Button>
              </ListItem>
             
             
              
              
              
            </List>
          </SubMenuWrapper>
        </List>
      
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky >
              Stock
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.stockItemCategoryLink()}
                  startIcon={<CategoryTwoToneIcon />}
                  aria-label="Stock"
                >
                  Stock (Alt+K)
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.sitesStockSummaryLink()}
                  startIcon={<AssessmentIcon />}
                  aria-label=" Sites Stock"
                >
                  Balance Items (Alt+B)
                </Button>
              </ListItem>
              </List>
              </SubMenuWrapper>
              </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky >
              My Company & Settings
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
             
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.profileDetailLink()}
                  startIcon={<BusinessIcon />}
                  aria-label=" Store Profile"
                >
                  Business Profile (Alt+P)
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.invoiceTnCLink()}
                  startIcon={<StoreIcon />}
                  aria-label="Set Invoice TnC"
                >
                  Invoice Settings
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.profileSettingsLink()}
                  startIcon={<SettingsIcon />}
                >
                  Software Settings(Alt+S)
                </Button>
              </ListItem>
              {data?.myStores?.length > 0 && (
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={ApplicationLinks.storeChangeLink()}
                    startIcon={<StoreIcon />}
                    aria-label=" Store Profile"
                  >
                    Switch account   
                  </Button>
                </ListItem>
              )}

              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.staffLink()}
                  startIcon={<GroupAddIcon />}
                  aria-label="Manage Staff Users"
                >
                  Users management
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

         
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              REPORTS
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.billingChartLink()}
                  startIcon={<AssessmentIcon />}
                  aria-label="BILLING CHART"
                >
                  Rental Stats (Alt+R)
                </Button>
              </ListItem>
             
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.allSitesLedgerLink()}
                  startIcon={<AssessmentIcon />}
                  aria-label="All Sites Ledger"
                >
                  Billing Summary
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.ledgerSummaryLink()}
                  startIcon={<AssessmentIcon />}
                  aria-label="Sites Ledger"
                >
                  Ledger Summary
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.gstr1SummaryLink()}
                  startIcon={<AssessmentIcon />}
                  aria-label="GSTR1"
                >
                  GSTR1 Report
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.gstr3bLink()}
                  startIcon={<SummarizeIcon />}
                  aria-label="GSTR 3B"
                >
                  GSTR 3B Report
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
       
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              More
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              {/* <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.activityLink()}
                  startIcon={<DirectionsWalkTwoToneIcon />}
                >
                  Activity
                </Button>
              </ListItem> */}

             
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.pendingBillsLink()}
                  startIcon={<ReceiptIcon />}
                >
                  Software usage bills
                </Button>
              </ListItem>

              {process.env.OFFLINE && (
                <>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to={ApplicationLinks.subscriptionPlansLink()}
                      startIcon={<ReceiptIcon />}
                    >
                      Purchange/Activate Plan
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to={ApplicationLinks.restoreBackup()}
                      startIcon={<RestoreIcon />}
                      aria-label="Restore Backup"
                    >
                      Manage Backups
                    </Button>
                  </ListItem>
                </>
              )}
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={ApplicationLinks.contactUsLink()}
                  startIcon={<ContactSupportIcon />}
                >
                  Contact Us
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  onClick={handleLogout}
                  startIcon={<LogoutIcon />}
                >
                  Logout
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
      {openNewSite && (
        <AddAccountItem
         customer={newCustomer}
          onClose={(newAccount?: Bill) => {
            setNewCustomer(null)
            setOpenNewSite(false)
            setNewAccount(newAccount)
            if (newAccount) {
              setCreateOutwardChallan(true)
            }
          }}
        />)}
        
      {createOutwardChallan && (
        <IssueMoreItem
          bill_id={newAccount?.id}
          customer_id={newAccount?.customer.id}
          onClose={() => {
            setNewAccount(null)
            setCreateOutwardChallan(false)
          }
          }
          billInfo={newAccount}
          billType={newAccount?.billType}
        />
      )}

      {createInwardChallan && (
        <SubmitItem
          bill_id={null}
          customer_id={null}
          onClose={(success?: boolean, site?: SiteDto) => {
            setCreateInwardChallan(false)
            if (site) {
              navigate(`/slips/view-slips/${site.id}/${site.customerId}?tab_index=0`)
            }
          }}
          billType={null}
        />
      )}

      {showReceiveLost && (
        <SubmitItem
          bill_id={null}
          customer_id={null}
          onClose={(success?: boolean, site?: SiteDto) => {
            setReceiveLost(false)
            if (site) {
              navigate(`/slips/view-slips/${site.id}/${site.customerId}?tab_index=0`)
            }
          }}
          submission_type={'LOST'}
          billType={null}
        />
      )}
      {showCreateMonthlyBill && (
        <CreateMonthlyBill
          onClose={(site?: SiteDto) => {
            setShowCreateMonthlyBill(false)
            if (site) {
              navigate(`/slips/view-slips/${site.id}/${site.customerId}?tab_index=5`)
            }
          }}
          account={null}
        />
      )}

      {showAddPayment && (
        <AddEditPaymentItem
          onClose={() => {
            setShowAddPayment(false)
          }}
          onSubmitSuccess={(site?: SiteDto) => {
            setShowAddPayment(false)
            if (site) {
              navigate(`/slips/view-slips/${site.id}/${site.customerId}?tab_index=4&hide_add_payment=true`)
            }
          }}
        />
      )}

      {showOtherCharge && (
        <OtherCharges
          bill_id={null}
          customer_id={null}
          onClose={(site?: SiteDto) => {
            setShowOtherCharge(false)
            if (site) {
              navigate(`/slips/view-slips/${site.id}/${site.customerId}?tab_index=0`)
            }
          }}
          title={'Add Other Charges'}
          isQuotation={false}
        />
      )}

       {showAddCustomer && (
        <AddCustomer
          onClose={(customer) => {
            setShowAddCustomer(false)
            setNewCustomer(customer)
            setOpenNewSite(true)
          }}
        />
      )}
    </>
  )
}

export default SidebarMenu
