import { FC, useState, useEffect, useRef, ReactNode } from 'react'
import {
  Box,
  Grid,
  Tabs,
  Tab,
  Popover,
  List,
  ListItem,
  Button,
} from '@mui/material'

// Components
import { SlipsScreenType, TabPanel, TabsEnums } from '../../../components'
import {
  AllItemComponent,
  OutItemComponent,
  LeftItemComponent,
  InItemComponent,
} from '.'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  fetchSlipsListAction,
  fetchSlipsResetAction,
} from '../../../store/slips'
import { useLocation, useParams } from 'react-router'
import { PaymentItemComponent } from './PaymentItemComponent'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { getSlipPDFAction, getSlipPDFResetAction } from '../../../store/items'
import { showAlertAction } from '../../../store/alerts'
import { AddEditPaymentItem } from './AddEditPayment'
import PageContainerWrapper from '../../../components/PageContainerWrapper'
import PageTitleWrapper from '../../../components/PageTitleWrapper'
import SlipPageHeader from './PageHeader'
import {
  getBalanceItemsCheckBoxValue,
  getFormattedEndDateString,
  getFormattedStartDateString,
  getSlipsCheckBoxValue,
} from '../../../utils'
import { activeTabColor } from '../CurrentAccounts'
import { SlipData, SlipPDFRequest } from 'src/services'
import MonthlyBills from '../MonthlyBills'
import Ledger from '../Ledger'
import { AccountActionBar } from './AccountActionsBar'
import { EmptyWithAddButton } from '../../../components/Empty/EmptyWithAddButton'
import IssueMoreItem from '../CurrentAccounts/IssueMoreItem'
import SubmitItem from '../CurrentAccounts/SubmitItem'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const getslipDataToShow = (
  searchTerm,
  searchResults: SlipData,
  data: SlipData
) => (searchTerm?.length > 0 ? searchResults : data)

export interface ViewSlipsProps {
  children?: React.ReactNode
}
export const tabBarColor = '#2e5a88'
export const tabBarTextColor = 'white'

const ViewSlips: FC<ViewSlipsProps> = ({}) => {
  const { bill_id, customer_id } = useParams()
  const { screen_type } = useParams()
  const { search } = useLocation()
  const dispatchAction = useAppDispatch()
  const query = new URLSearchParams(search)
  const defaultTabIndex = query ? Number(query.get('tab_index')) : 0
  const hideAddPayment = query ? Boolean(query.get('hide_add_payment')) : false

  const [checked, setChecked] = useState<boolean>(getSlipsCheckBoxValue())
  const [balanceItemsChecked, setBalanceItemsChecked] = useState<boolean>(
    getBalanceItemsCheckBoxValue()
  )

  const {
    data,
    searchResults,
    searchTerm,
    loading: manageSlipsLoading,
  } = useAppSelector((state) => state.manageSlips)

  const {
    list: { data: customerAccounts },
  } = useAppSelector((state) => state.manageCustomerAccounts)

  const {
    data: slipPdfLink,
    success: getPDFSuccess,
    error: getPDFError,
    message,
  } = useAppSelector((state) => state.slipPDF)

  const [value, setValue] = useState(0)
  const ref = useRef<any>(null)
  const [showMore, setShowMore] = useState<boolean>(false)
  const [issueMoreItem, setIssueMoreItem] = useState<boolean>(false)
  const [submitItem, setSubmitItem] = useState<boolean>(false)

  const [selectScreenType, setSelectedScreenType] = useState<SlipsScreenType>(
    SlipsScreenType.Account
  )
  const [subheaderInfo, setSubheaderInfo] = useState<string | ReactNode>('')

  const [editItem, openEditItem] = useState<boolean>(false)
  const [dailyEntryDate, setDailyEntryDate] = useState(null)

  const [emptyResult, setEmptyResult] = useState<boolean>(false)

  type EmptyItem = {
    buttonTitle?: string
    message?: string
    callBack?: () => void
  }

  const TITLE_ISSUE_ITEMS = 'ISSUE ITEMS'
  const TITLE_RECEIVE_ITEMS = 'RECEIVE ITEMS'
  const TITLE_ADD_PAYMENT = 'ADD PAYMENT'

  const emptyItemMap = {
    0: {
      buttonTitle: TITLE_ISSUE_ITEMS,
      message:
        'You have not issued any item in this account yet. Click this button to issue items.',
    },
    1: {
      buttonTitle: TITLE_ISSUE_ITEMS,
      message:
        'You have not issued any item in this account yet. Click this button to issue items.',
    },
    2: {
      buttonTitle: TITLE_RECEIVE_ITEMS,
      message:
        'You have not received any item in this account yet. Click this button to receive items.',
    },
    3: {
      buttonTitle: TITLE_ISSUE_ITEMS,
      message:
        'You have not issued any item in this account yet. Click this button to issue items.',
    },
    4: {
      buttonTitle: TITLE_ADD_PAYMENT,
      message:
        'No payment entry has been found in this account. Click this button to add payment entry.',
    },
  }

  useEffect(() => {
    if (data) {
      if (value === 0) {
        setEmptyResult(data.all && data.all.length === 0)
      } else if (value === 1) {
        setEmptyResult(data.out && data.out.length === 0)
      } else if (value === 2) {
        setEmptyResult(data.in && data.in.length === 0)
      } else if (value === 3) {
        setEmptyResult(data.left && data.left.length === 0)
      } else if (value === 4) {
        setEmptyResult(data.payment && data.payment.length === 0)
      } else {
        setEmptyResult(false)
      }
    } else {
      setEmptyResult(false)
    }
  }, [data, value])

  useEffect(() => {
    return () => {
      dispatchAction(getSlipPDFResetAction()) // To manage the Alert
      dispatchAction(fetchSlipsResetAction())
    }
  }, [])

  const addPaymentRequest = {
    billId: Number(bill_id),
    paymentId: null,
    amount: null,
    paymentDate: null,
    note: null,
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }
  useEffect(() => {
    const type =
      screen_type === 'DATE'
        ? SlipsScreenType.Date
        : screen_type === 'Stock'
        ? SlipsScreenType.Stock
        : SlipsScreenType.Account
    setSelectedScreenType(type)
    if (type === SlipsScreenType.Date) {
      onDateSelect(new Date())
    } else if (bill_id) {
      dispatchAction(fetchSlipsListAction(bill_id))
      window.scrollTo({ behavior: 'smooth', top: 0 })
    }
    if (defaultTabIndex && defaultTabIndex > 0) {
      setValue(defaultTabIndex)
      if (defaultTabIndex === 4 && hideAddPayment != true) {
        openEditItem(true)
      }
    }
  }, [bill_id, dispatchAction])

  const onDateSelect = (newDate: Date) => {
    setDailyEntryDate(newDate)
    dispatchAction(
      fetchSlipsListAction(
        0,
        getFormattedStartDateString(newDate),
        getFormattedEndDateString(newDate)
      )
    )
  }

  const handleViewPDF = (showRate?: boolean, title?: string, challanId?: number) => {
    const slipType =
      value === 0
        ? TabsEnums.All
        : value === 1
        ? TabsEnums.Out
        : value === 2
        ? TabsEnums.In
        : value === 3
        ? "REST"
        : value === 4
        ? TabsEnums.Payment
        : value === 5
        ? TabsEnums.MonthlyBills
        : value === 6
        ? TabsEnums.Ledger
        : TabsEnums.All

    let getPDFRequest: SlipPDFRequest = {
      billId: Number(bill_id),
      showRate: showRate === true,
      showGoodsValue: showRate === null,
      slipType: `${slipType}`,
      isRojnamcha: false,
      from: null,
      to: null,
      challanId: challanId,
      hideTotalEntries: getSlipsCheckBoxValue()
    }

    if (screen_type === SlipsScreenType.Date) {
      getPDFRequest = {
        billId: Number(bill_id),
        showRate: showRate === true,
        showGoodsValue: showRate === null,
        isRojnamcha: true,
        from: getFormattedStartDateString(dailyEntryDate, false),
        to: getFormattedEndDateString(dailyEntryDate, false),
        slipType: `${slipType}`,
        title: title,
        hideTotalEntries: getSlipsCheckBoxValue()
      }
    }
    dispatchAction(getSlipPDFAction(getPDFRequest))
  }

  useEffect(() => {
    if (getPDFSuccess) {
      window.open(slipPdfLink, '_blank')
    } else if (getPDFError && message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
    }
  }, [getPDFSuccess, getPDFError, message])

  useEffect(() => {
    if (
      customerAccounts?.length > 0 &&
      bill_id &&
      selectScreenType === SlipsScreenType.Account
    ) {
      const billData = customerAccounts.find((account) => account.id == bill_id)
      console.log({ billData })
      const title = `${billData?.customer.name}, ${billData?.siteAddress}`
      setSubheaderInfo(title)
    } else {
      setSubheaderInfo("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill_id])

  useEffect(() => {
    if (
      data &&
      selectScreenType === SlipsScreenType.Account && data.accountInfo
    ) {
      const billData = data.accountInfo
      const title = `${billData?.customer.name}, ${billData?.siteAddress}`
      setSubheaderInfo(title)
    } else {
      setSubheaderInfo("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <PageTitleWrapper>
        <SlipPageHeader
          onDateSelect={onDateSelect}
          screenType={selectScreenType}
          subheaderInfo={subheaderInfo}
          bill={data.accountInfo}
        />
      </PageTitleWrapper>
      <PageContainerWrapper>
        <Grid
          container
          direction="row"
          alignItems="stretch"
          spacing={0}
          sx={{ padding: 0 }}
        >
          <Grid item xs={12}>
            <Tabs
              id="slips_tab_container"
              defaultValue={0}
              value={value}
              onChange={handleTabChange}
              aria-label="Slips"
              sx={{ backgroundColor: tabBarColor, color: tabBarTextColor }}
              textColor={tabBarTextColor}
              TabIndicatorProps={{
                style: {
                  transition: 'none',
                  backgroundColor: activeTabColor,
                  height: 4,
                },
              }}
            >
              <Tab label={TabsEnums.All} {...a11yProps(0)} id="id_tab_all" />
              <Tab label={TabsEnums.Out} {...a11yProps(1)} id="id_tab_out" />
              <Tab label={TabsEnums.In} {...a11yProps(2)} id="id_tab_in" />
              {selectScreenType === SlipsScreenType.Account && (
                <Tab
                  label={TabsEnums.Left}
                  {...a11yProps(3)}
                  id="id_tab_left"
                />
              )}
              {selectScreenType === SlipsScreenType.Account && (
                <Tab
                  id="id_tab_payment"
                  label={TabsEnums.Payment}
                  {...a11yProps(4)}
                  sx={{ width: 120 }}
                />
              )}
              {selectScreenType === SlipsScreenType.Account && (
                <Tab label={TabsEnums.MonthlyBills} {...a11yProps(4)} />
              )}
              {data &&
                data.accountInfo &&
                data.accountInfo.totalCeilingArea === 0 &&
                data.accountInfo.isRecurringEnabled &&
                selectScreenType === SlipsScreenType.Account && (
                  <Tab label={TabsEnums.Ledger} {...a11yProps(4)} />
                )}
            </Tabs>
            {value < 5 && emptyResult && (
              <EmptyWithAddButton
                onClickAdd={(title) => {
                  if (title === TITLE_ISSUE_ITEMS) {
                    setIssueMoreItem(true)
                  } else if (title === TITLE_RECEIVE_ITEMS) {
                    setSubmitItem(true)
                  } else if (title === TITLE_ADD_PAYMENT) {
                    openEditItem(true)
                  }
                }}
                buttonTitle={emptyItemMap[value].buttonTitle}
                message={emptyItemMap[value].message}
              />
            )}

            {!emptyResult && value < 5 && (
              <AccountActionBar
                refr={ref}
                billInfo={data?.accountInfo}
                //value here is tab index
                showReceiveItems={true} //{value === 2 || value === 0}
                showAddEntry={true} //{value < 2}
                showAddPayment={value === 4}
                showCheckbox={value < 3}
                showBalanceItemsCheckbox={value === 3}
                onViewPDF={() => {
                  if (value < 2) {
                    setShowMore(true)
                  } else {
                    handleViewPDF(false, null, null)
                  }
                }}
                onAddPayment={() => {
                  openEditItem(true)
                }}
                setTotalHidden={(hidden) => {
                  setChecked(hidden)
                }}
                setShowOnlyBalanceItems={(showOnly) => {
                  setBalanceItemsChecked(showOnly)
                }}
                selectTab={(index) => {
                  setValue(index)
                }}
              />
            )}
            <Popover
              open={showMore}
              anchorEl={ref.current}
              onClose={() => {
                setShowMore(false)
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <List sx={{ p: 0 }} component="nav">
                {value < 2 && (
                  <>
                    <ListItem>
                      <Button
                        sx={{ fontWeight: 400 }}
                        startIcon={<PictureAsPdfIcon />}
                        onClick={() => {
                          setShowMore(false)
                          handleViewPDF(true, null)
                        }}
                      >
                        PDF with rate
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        sx={{ fontWeight: 400 }}
                        startIcon={<PictureAsPdfIcon />}
                        onClick={() => {
                          setShowMore(false)
                          handleViewPDF(false, null)
                        }}
                      >
                        PDF without rate
                      </Button>
                    </ListItem>
                  </>
                )}
              </List>
            </Popover>

            <Box sx={{ width: '100%', mt: 0 }}>
              <Box
                sx={{
                  borderRadius: 1,
                }}
              ></Box>

              <>
                <TabPanel value={value} index={0}>
                  <AllItemComponent
                    data={
                      getslipDataToShow(searchTerm, searchResults, data)?.all
                    }
                    separatorHeight={Number(0.2)}
                    billId={Number(bill_id)}
                    onClickViewPDF={handleViewPDF}
                    screenType={selectScreenType}
                    billType={
                      data?.accountInfo ? data?.accountInfo.billType : ''
                    }
                    checked={checked}
                    account={data?.accountInfo}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <OutItemComponent
                    data={
                      getslipDataToShow(searchTerm, searchResults, data)?.out
                    }
                    separatorHeight={0.2}
                    billId={Number(bill_id)}
                    onClickViewPDF={handleViewPDF}
                    screenType={selectScreenType}
                    billType={
                      data?.accountInfo ? data?.accountInfo.billType : ''
                    }
                    checked={checked}
                    account={data?.accountInfo}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <InItemComponent
                    data={
                      getslipDataToShow(searchTerm, searchResults, data)?.in
                    }
                    separatorHeight={0.2}
                    billId={Number(bill_id)}
                    onClickViewPDF={handleViewPDF}
                    screenType={selectScreenType}
                    billType={
                      data?.accountInfo ? data?.accountInfo.billType : ''
                    }
                    checked={checked}
                    account={data?.accountInfo}
                  />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <LeftItemComponent
                    data={
                      getslipDataToShow(searchTerm, searchResults, data)?.left
                    }
                    separatorHeight={0.2}
                    showOnlyBalanceItems={balanceItemsChecked}
                  />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <PaymentItemComponent
                    data={data?.payment}
                    separatorHeight={0.2}
                    billId={Number(bill_id)}
                  />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <MonthlyBills bill={data.accountInfo} />
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <Ledger bill={data.accountInfo} />
                </TabPanel>
              </>
            </Box>
          </Grid>
        </Grid>
        {editItem && (
          <AddEditPaymentItem
            item={addPaymentRequest}
            onClose={() => {
              openEditItem(false)
            }}
            onSubmitSuccess={() => {
              openEditItem(false)
            }}
          />
        )}
        {issueMoreItem && (
          <IssueMoreItem
            bill_id={+bill_id}
            customer_id={+customer_id}
            onClose={() => setIssueMoreItem(false)}
            billType={data.accountInfo.billType}
            billInfo={data.accountInfo}
          />
        )}
        {submitItem && (
          <SubmitItem
            bill_id={+bill_id}
            customer_id={+customer_id}
            onClose={() => setSubmitItem(false)}
            billType={data.accountInfo.billType}
            bill={data.accountInfo}
          />
        )}
      </PageContainerWrapper>
    </>
  )
}

export default ViewSlips
