import { FC, useEffect, useState } from 'react'
import {
  Box,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material'
import { Autocomplete } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useForm, Controller } from 'react-hook-form'

// Components
import { AppDialog, Loader } from '../../../components'

// Redux
import {
  getFormattedEndDateString,
  getFormattedStartDateString,
  getFirstDayoftheCurrentMonth,
  getFormattedDateTimeIST,
  getEncodedFormattedDate,
  getEndDateOfMonth,
  getBillFormat,
  setBillFormat,
} from '../../../utils'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  createMonthlyBillAction,
  createMonthlyBillResetAction,
  fetchMonthlyBillsAction,
} from '../../../store/monthlyBills'
import { getInvoiceNumberAction } from '../../../store/slips'
import { showAlertAction } from '../../../store/alerts'
import { getNextInvoiceNo } from './Services'
import { ChallanDropDown } from '../slips/ChallanDropDown'
import { Bill, Challan } from '../../../../src/services'
import { SiteDto } from '../../../../src/components/SitesAutoComplete/Services'
import SiteAutocomplete from '../../../../src/components/SitesAutoComplete/SiteAutocomplete'

type FormInputs = {
  discount: number
  createDate: Date
  startDate: Date
  endDate: Date
  month: Date
  invoiceNo: number
  billFormat?: string // Add billFormat to the form inputs
}

export interface CreateMonthlyBillProps {
  onClose: (site?: SiteDto) => void
  account?: Bill
}

export const CreateMonthlyBill: FC<CreateMonthlyBillProps> = ({
  onClose,
  account,
}) => {
  const dispatchAction = useAppDispatch()
  const { loading, success, message } = useAppSelector(
    (state) => state.createMonthlyBill
  )
  const { data: getInvoiceData } = useAppSelector((state) => state.getInvoice)

  const defaultValues = {
    discount: 0,
    createDate: new Date(),
    startDate: getFirstDayoftheCurrentMonth(),
    endDate: new Date(),
    month: getFirstDayoftheCurrentMonth(),
    invoiceNo: getInvoiceData?.docNo ?? '',
  }

  const { control, handleSubmit, reset, getValues, setValue } = useForm<FormInputs>({
    defaultValues,
    mode: 'all',
  })
  const [billId, setBillId] = useState<number>(0)
  const [ratePerSqft, setRatePerSqft] = useState<number | null>(null)
  const [site, setSite] = useState<SiteDto | null>(null)

  const [isClose, setClosePopup] = useState<boolean>(false)
  const [showChallan, setShowChallan] = useState<boolean>(false)
  const [dateFilter, setDateFilter] = useState('month')
  const [selectedChallan, setSelectedChallan] = useState<Challan | null>()
  const { data: userData } = useAppSelector((state) => state.userData)

  const fetchInvoiceNoByDate = ((date: string) => {
    getNextInvoiceNo(billId, date).then(
      (response) => {
        if (response.success) {
          setValue("invoiceNo", response.data.docNo)
        } else {
          dispatchAction(
            showAlertAction({
              message: response.message,
              severity: 'error',
            })
          )
        }
      }
    )
  })

  function showBillFormat() {
    return billId != null && billId != undefined && billId > 0 && (ratePerSqft === 0 || !ratePerSqft)
  }

  const handleDateFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDateFilter((event.target as HTMLInputElement).value)
  }

  useEffect(() => {
    if (account) {
      setBillId(account.id)
      setRatePerSqft(account.ratePerSqft)
    }
  }, [account])

  useEffect(() => {
    dispatchAction(createMonthlyBillResetAction())
    if (billId) {
      dispatchAction(getInvoiceNumberAction(billId))
    }
  }, [billId])

  useEffect(() => {
    if (userData?.config) {
      setShowChallan(userData.config.enabledChallanBilling);
    } else {
      setShowChallan(false); // Default if config is not available
    }

    const format = getBillFormat() 
    setValue("billFormat", format ? format : "FORMAT_3")
    
  }, [userData]);

  useEffect(() => {
    if (getInvoiceData?.docNo) {
      reset({ ...getValues(), invoiceNo: getInvoiceData?.docNo })
    }
  }, [getInvoiceData])

  const submitHandle = handleSubmit((data: FormInputs) => {
    const { startDate, endDate, invoiceNo, createDate, discount, billFormat } = data
    if (!createDate || (dateFilter !== "month" && (!startDate || !endDate))) {
      return false
    }
    let date1 = startDate
    let date2 = endDate
    if (dateFilter === "month") {
      date1 = null
      date2 = null
    }
    if(showBillFormat() && billFormat) {
      setBillFormat(billFormat);
    }

    dispatchAction(
      createMonthlyBillAction({
        fromDate: date1 ? getFormattedStartDateString(new Date(startDate), false) : null,
        toDate: date2 ? getFormattedEndDateString(new Date(endDate), false) : null,
        createDate: getFormattedDateTimeIST(createDate),
        accountId: billId,
        invoiceNo: invoiceNo,
        discount: discount,
        challanId: dateFilter === 'date-range' ? selectedChallan?.id : null,
        billFormat: showBillFormat() ? billFormat : null // Pass the selected bill format to the API
      })
    )
  })

  useEffect(() => {
    if (success) {
      dispatchAction(fetchMonthlyBillsAction(billId))
      dispatchAction(
        showAlertAction({
          message: 'Successfully created',
          severity: 'success',
        })
      )
      dispatchAction(createMonthlyBillResetAction())
      onClose(site)
    } else if (message) {
      dispatchAction(showAlertAction({ message: message, severity: 'error' }))
      dispatchAction(createMonthlyBillResetAction())
    }
  }, [success, message])

  return (
    <AppDialog
      id="edit-item"
      title={'Create New Monthly Bill'}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      maxWidth="sm"
    >
      {loading && <Loader />}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={0}
      >
         {(!account || account.id === 0) && (
           <Grid item xs={12} ml={1}  mr={1}>
             <SiteAutocomplete onSiteSelected={(site?: SiteDto) => {
               setBillId(site?.id)
               setRatePerSqft(site?.ratePerSqft)
               setSite(site)
             }}/>
             </Grid>
            )}
        <Grid item xs={12} mr={2}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid item={true} xs={12}>
              <Controller
                control={control}
                name={'createDate'}
                rules={{ required: 'This is required.' }}
                render={({ field, fieldState }) => {
                  const { error } = fieldState
                  const isError = error || field.value == 'Invalid Date'
                  return (
                    <DatePicker
                      label="Invoice date"
                      value={field.value}
                      onChange={(date) => {
                        field.onChange(date)
                        fetchInvoiceNoByDate(getEncodedFormattedDate(date))
                      }}
                      format="dd/MM/yyyy hh:mm a"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                          variant: 'outlined',
                          helperText: isError
                            ? error?.message || 'Invalid Date'
                            : null,
                          error: !!isError,
                        },
                      }}
                    />
                  )
                }}
              />
            </Grid>
            {showBillFormat() && (
              <Grid item={true} xs={12}>
                <Controller
                  name="billFormat"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      options={[
                        { label: 'FORMAT_3', id: 'FORMAT_3' },
                        { label: 'FORMAT_2', id: 'FORMAT_2' },
                        { label: 'FORMAT_1', id: 'FORMAT_1' },
                       
                      ]}
                       
                      size='small'
                      getOptionLabel={(option) => option.label}
                      value={value ? { label: value, id: value } : null}
                      onChange={(event, newValue) => {
                        onChange(newValue ? newValue.id : null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Bill Format"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}

            {/* The rest of your form fields */}
            <Grid container spacing={2} mt={0.5}>
              <Grid item={true} xs={12}>
                <Controller
                  name={'invoiceNo'}
                  control={control}
                  rules={{ required: 'This is required.' }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      size='small'
                      helperText={error ? error.message : null}
                      size="small"
                      error={!!error}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={'Invoice Number'}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item={true} xs={12} sx={{ ml: 1 }}>
                <FormControl>
                  <FormLabel id="date-filter-label">Bill duration</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="date-filter-label"
                    name="date-filter-group"
                    value={dateFilter}
                    onChange={handleDateFilterChange}
                  >
                    <FormControlLabel
                      value="month"
                      control={<Radio />}
                      label="Complete (from start to end)"
                    />
                    <FormControlLabel
                      value="date-range"
                      control={<Radio />}
                      label="Date Range"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {dateFilter === 'date-range' && (
                <>
                  {showChallan && (
                    <Grid item={true} xs={12}>
                      <ChallanDropDown
                        accountId={Number(billId)}
                        onChallanChange={(challan) => {
                          setSelectedChallan(challan)
                        }}
                        challan_type={"OUT"}
                      />
                    </Grid>
                  )}
                  <Grid item={true} xs={12} mt={0}>
                    <Controller
                      control={control}
                      name={'startDate'}
                      rules={{ required: 'This is required.' }}
                      render={({ field, fieldState }) => {
                        const { error } = fieldState
                        const isError = error || field.value == 'Invalid Date'
                        return (
                          <DatePicker
                            label="Select Start Date"
                            value={field.value}
                            onChange={(date) => {
                              field.onChange(date)
                              setValue('endDate', getEndDateOfMonth(date))
                            }}
                            format="d MMM yyyy"
                            slotProps={{
                              textField: {
                                size: 'small',
                                fullWidth: true,
                                variant: 'outlined',
                                helperText: isError
                                  ? error?.message || 'Invalid Date'
                                  : null,
                                error: !!isError,
                              },
                            }}
                          />
                        )
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Controller
                      control={control}
                      name={'endDate'}
                      rules={{ required: 'This is required.' }}
                      render={({ field, fieldState }) => {
                        const { error } = fieldState
                        const isError = error || field.value == 'Invalid Date'
                        return (
                          <DatePicker
                            label="Select End Date"
                            value={field.value}
                            onChange={(date) => field.onChange(date)}
                            format="d MMM yyyy"
                            slotProps={{
                              textField: {
                                size: 'small',
                                fullWidth: true,
                                variant: 'outlined',
                                helperText: isError
                                  ? error?.message || 'Invalid Date'
                                  : null,
                                error: !!isError,
                              },
                            }}
                          />
                        )
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item={true} xs={12}>
                <Controller
                  name={'discount'}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      size='small'
                      helperText={error ? error.message : null}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={'Discount'}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </AppDialog>
  )
}
