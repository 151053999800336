import { Autocomplete, TextField, CircularProgress, IconButton, Box, Typography, Divider } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { showAlertAction } from '../../store/alerts';
import { SiteDto, fetchSites } from './Services';

interface SiteAutocompleteProps {
  small?: boolean;
  onSiteSelected: (selectedSite: SiteDto | null) => void;  // Callback when a site is selected
}

const SiteAutocomplete: FC<SiteAutocompleteProps> = ({ small, onSiteSelected }) => {
  const [sites, setSites] = useState<SiteDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedSite, setSelectedSite] = useState<SiteDto | null>(null);
  const dispatch = useDispatch(); // Access to dispatch actions

  // Fetch site data from the API
  const fetchOpenSites = () => {
    setLoading(true);
    setError(null);
    fetchSites().then((response) => {
      setLoading(false);
      if (response?.success) {
        setSites(response.data);       
      } else {
        dispatch(showAlertAction({
          message: response?.message,
          severity: 'error',
        }));
      }
    });
  };

  // Load site data on component mount or when sites list is empty
  useEffect(() => {
    if (sites.length === 0) {
      fetchOpenSites();
    }
  }, [sites.length]);

  // Handle manual reload
  const handleReload = () => {
    fetchOpenSites();
  };

  // Handle site selection
  const handleSiteChange = (event: any, newValue: SiteDto | null) => {
    setSelectedSite(newValue);
    onSiteSelected(newValue);  // Notify parent component about the selection
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%', pt:0 }}>
      <Autocomplete
        size='small'
        id="site-autocomplete"
        options={sites}
        getOptionLabel={(option: SiteDto) =>
          `${option.siteAddress} (${option.customerName}, ${option.customerMobile}, ${option.id})`
        }
        renderOption={(props, option) => (
          <>
          <Box component="li" {...props} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography variant="body1" sx={{width:'100%', fontWeight: 'bold', textAlign: 'left'}}>
              
              Site ID:{option.id}, Address: {option.siteAddress}
            </Typography>
            <Typography variant="body2" color='green' sx={{width:'100%', textAlign: 'left' }}>
            {option.customerName}, {option.customerMobile}
            </Typography>
          </Box>
          <Divider/>
          </>
        )}
        
        value={selectedSite}
        onChange={handleSiteChange}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{mt:0}}
            label="Select a Site"
            variant="outlined"
            error={!!error}
            helperText={error || ' '}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        disabled={loading}
        sx={{mt: 0, mr: small ? 1 : 0.5, width: '100%'  }}
      />
      <IconButton
        onClick={handleReload}
        disabled={loading}
        sx={{
          backgroundColor: 'green',
          color: 'white',
          marginLeft: 1, // Space between the autocomplete and button
          height: '37px', // Adjust height for better alignment
          width: '37px',  // Square shape
          borderRadius: 1, // Ensure the button is square-shaped
          mt: small ? 1: 0
        }}
      >
        <RefreshIcon />
      </IconButton>
    </Box>
  );
};

export default SiteAutocomplete;
