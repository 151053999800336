import {
  FC,
  ChangeEvent,
  useState,
  useEffect,
  ReactNode,
  Fragment,
} from 'react'
import { useNavigate } from 'react-router'
import {
  Box,
  Link,
  Card,
  CardContent,
  Grid,
  CardHeader,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Alert,
  Select,
  Collapse,
  IconButton,
  Snackbar,
  Button,
  Divider,
  Typography,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { SelectChangeEvent } from '@mui/material/Select'

import {
  PrepareIssueItem,
  IssueItemRequest,
  TimeTypeEnum,
  EntryType,
  Bill,
  Challan,
} from '../../../services'

// Components
import { AppDialog, Loader, ChallanSection } from '../../../components'
import ItemComponent, { HORZ_SPACING } from '../IssueItems/Item'
import PreviewItem from '../IssueItems/PreviewItem'

// Iconse
import CloseIcon from '@mui/icons-material/Close'

// Utils
import { getFormattedDate } from '../../../utils'

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { issueItemAction, issueItemRequestAction } from '../../../store/items'
import {
  fetchBillbyIdAction,
  syncSelectOptionsAction,
} from '../../../store/bills'
import { fetchSlipsListAction } from '../../../store/slips'
import { showAlertAction } from '../../../store/alerts'
import { fetchCustomerAccountAction } from '../../../store/customerAccounts'
import CustomNumberField from '../Users/profile/CustomNumberField'
import SiteAutocomplete from '../../../../src/components/SitesAutoComplete/SiteAutocomplete'
import { SiteDto } from '../../../../src/components/SitesAutoComplete/Services'
export interface SubmitItemProps {
  onClose?: (success?: boolean, site?: SiteDto) => void
  bill_id: number
  customer_id: number
  submission_type?: 'LOST' | 'SUBMIT'
  billType: string
  selectedDate?: string
  bill?: Bill
  challanNo?: string
}

const SubmitItem: FC<SubmitItemProps> = ({
  onClose,
  bill_id,
  customer_id,
  submission_type = 'SUBMIT',
  selectedDate,
  billType,
  bill,
  challanNo,
}) => {
  const navigate = useNavigate()
  const dispatchAction = useAppDispatch()
  const {
    selectOptions,
    loading: billFetchLoading,
    data: manageBillsData,
  } = useAppSelector((state) => state.manageBills)
  const { success: issueItemSuccess, message: submitErrorMessage } =
    useAppSelector((state) => state.issueItems)
  const {
    list: { data: customerAccounts },
  } = useAppSelector((state) => state.manageCustomerAccounts)

  const [popupTitle, setPopupTitle] = useState<string | ReactNode>(
    submission_type == 'LOST'
    ? 'LOST OR DAMAGE'
    : 'INWARD CHALLAN'
  )

  const [billId, setBillId] = useState<number>(0)
  const [customerId, setCustomerId] = useState<number>(0)
  const [rentType, setRentType] = useState<string | null>(null)
  const [siteAddress, setSiteAddress] = useState<string | null>(null)
  const [site, setSite] = useState<SiteDto | null>(null)

  const [items, setItem] = useState<PrepareIssueItem[]>([])
  const [date, setDate] = useState<Date | null>(null)
  const [deposite, setDeposite] = useState<number | null>(null)
  const [timeType, setTimeType] = useState<string>(TimeTypeEnum.EVENING)
  const [isClose] = useState<boolean>(false)
  const [itemRequest, setItemRequest] = useState<IssueItemRequest | null>(null)
  const [serverError, setServerError] = useState<string | null>(null)
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false)
  const [selectedChallan, setSelectedChallan] = useState<Challan>(null)
  const [userDefinedChallanNumber, setDefinedChallanNumber] = useState('')

  const [vehicleNo, setVehicleNo] = useState<string | null>(null)

  // All manadatory fields are collected
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(true)

  useEffect(() => {
    if(bill_id) {
      setBillId(bill_id)
    }
    if(customer_id) {
      setCustomerId(customer_id)
    }

    if(billType) {
      setRentType(billType)
    }
  
  }, [bill_id, customer_id, billType])

  useEffect(() => {
    if (selectedDate) {
      setDate(new Date(selectedDate))
    }
  }, [selectedDate])

  useEffect(() => {
    if (billId) dispatchAction(fetchBillbyIdAction(billId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billId])
  useEffect(() => {
    setItemRequest((request) => {
      const formatedItem =
        items &&
        items.map((data) => {
          const updateData = { ...data, item: { ...data.item } }
          const { item } = updateData
          delete item.label
          delete item.category
          updateData.timeType = timeType
          updateData.areaOutEntryId = updateData.outAreaEntryId
          delete updateData.outAreaEntryId
          return updateData
        })
      return {
        ...request,
        ...(items && { items: formatedItem }),
        ...(customerId && { customerId: customerId }),
        ...(billId && {bill_id: billId }),
        ...(date && { date_entry: getFormattedDate(new Date(date)) }),
        ...(deposite && { deposite }),
      }
    })
  }, [items, timeType, date, deposite, customerId, billId])

  useEffect(() => {
    if (issueItemSuccess) {
      setItem([])
      setDeposite(0)
      setDate(new Date())
      setItemRequest(null)
      dispatchAction(
        showAlertAction({
          message: 'Item submitted Successfully',
          severity: 'success',
        })
      )
      onClose(true, site)
      dispatchAction(issueItemRequestAction())
      dispatchAction(fetchCustomerAccountAction('OPEN'))
      dispatchAction(fetchSlipsListAction(billId))
      navigate(`/slips/view-slips/${billId}/${customerId}`)
    }

    if (submitErrorMessage) {
      dispatchAction(issueItemRequestAction())
      setServerError(submitErrorMessage)
      setReadyToSubmit(false)
    }
  }, [issueItemSuccess, submitErrorMessage])

  useEffect(() => {
    if (siteAddress) {
      const title = `${
        submission_type == 'LOST'
          ? 'LOST OR DAMAGE'
          : 'INWARD CHALLAN'
      } (${
        siteAddress
      } )`
      setPopupTitle(title)
    } else if (customerAccounts?.length > 0 && billId && billId > 0) {
      const billData = customerAccounts.find((account) => account.id == billId)
      const title = `${
        submission_type == 'LOST'
          ? 'LOST OR DAMAGE'
          : 'INWARD CHALLAN'
      } (${
        billData?.siteAddress
      } )`
      setPopupTitle(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billId, bill, siteAddress])

  useEffect(() => {
    dispatchAction(syncSelectOptionsAction(items))
  }, [items])

  const submitHandle = () => {
    const { items, customerId, date_entry } = itemRequest
    if (
      !customerId ||
      (selectedChallan?.id === 0 && !date_entry) ||
      items.length <= 0 ||
      !selectedChallan
    ) {
      if (!date_entry) {
        setDatePickerOpen(true)
      }
      setReadyToSubmit(false)
    } else {
      itemRequest.vehicleNo = vehicleNo
      itemRequest.challanNo = userDefinedChallanNumber
        ? userDefinedChallanNumber
        : selectedChallan?.slipNo
      itemRequest.challanId = selectedChallan?.id as unknown as string
      dispatchAction(issueItemAction(itemRequest))
      setReadyToSubmit(true)
    }
  }
  const addItemHandler = (item) => {
    console.log('addItemHandler new item', item)
    const newArray = [item, ...items]
    setItem(newArray)
  }

  const submitAllHandler = () => {
    if (items.length > 0) {
      setItem([])
    }
    setTimeout(() => {
      if (manageBillsData?.enteries?.length > 0) {
        const allItems = manageBillsData?.enteries.map((item) => {
          const prepareItem = {
            entryType: EntryType.IN,
            quantity: item.availableQuantity,
            rate: item.rate,
            item: {
              id: item.id,
              label: item.itemName,
            },
          }
          return prepareItem
        })
        setItem(allItems)
      }
    }, 0)
  }

  const md = 4
  return (
    <AppDialog
      id="issue-more-item"
      title={popupTitle}
      submitButtonText="Submit"
      onClose={onClose}
      onSubmit={submitHandle}
      close={isClose}
      showErrorMessage={submitErrorMessage}
    >
      <Grid container direction="row" spacing={0} pl={2} pr={2}>
      {(!bill_id || bill_id === 0) && (
        <Grid item md={12}>
       
             <SiteAutocomplete onSiteSelected={(site: SiteDto) => {
               setBillId(site?.id)
               setCustomerId(site?.customerId)
               setRentType(site?.billType)
               setSiteAddress(site?.siteAddress)
               setSite(site)
             }}/>
           
        </Grid>
         )}
        {billId != null && billId != undefined && billId > 0 && (
        <Grid item md={12}>
          <ChallanSection
            challanNo={challanNo}
            userDefinedChallanNumber={userDefinedChallanNumber}
            setDefinedChallanNumber={setDefinedChallanNumber}
            selectedChallan={selectedChallan}
            setSelectedChallan={setSelectedChallan}
            bill_id={billId}
            setReadyToSubmit={setReadyToSubmit}
            date={date}
            setDate={setDate}
            datePickerOpen={datePickerOpen}
            setDatePickerOpen={setDatePickerOpen}
            challan_type={'IN'}
            mdValue={md}
          />
        </Grid>
        )}
        <Grid item xs={12}>
          <Collapse in={!readyToSubmit}>
            <Alert
              id="id_error_alert"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setReadyToSubmit(true)
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2, mt: 2 }}
            >
              {serverError ? serverError : 'Please fill all mandatory fields!'}
            </Alert>
          </Collapse>
        </Grid>{' '}
        {submission_type === 'SUBMIT' && (
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          ></Grid>
        )}
        <Grid item xs={12} pt={0}>
          {submission_type === 'LOST' && (
            <Typography variant="h5"> Select Items </Typography>
          )}
          {submission_type !== 'LOST' && (
            <>
              Select Items or
              <Link
                href="#"
                onClick={submitAllHandler}
                underline="always"
                sx={{ pl: 0.7, color: 'blue' }}
                id="id_link_submit_all"
              >
                receive all
              </Link>
            </>
          )}
        </Grid>
        <Grid item xs={12} pt={0.7}>
          <ItemComponent
            selectOptions={selectOptions}
            entryType={EntryType.IN}
            addItem={addItemHandler}
            submission_type={submission_type}
            billType={rentType}
            defaultRateZero={false}
          />
        </Grid>
        <Grid item xs={12}>
          {items.length > 0 && (
            <div
              style={{
                borderWidth: 0,
                borderColor: 'lightgray',
                borderStyle: 'solid',
              }}
            >
              {items.map((item, id) => {
                const key = `${item?.item?.id}_${id}`
                console.log({ item, id, key })
                return (
                  <Fragment key={key}>
                    {id > 0 && <Divider />}
                    <PreviewItem
                      item={item}
                      submission_type={submission_type}
                      entryType={EntryType.IN}
                      deleteItem={(itemId, outAreaEntryId) => {
                        const newItems = [...items]
                        const findIndex = newItems.findIndex(
                          (a) =>
                            a.item?.id === itemId &&
                            outAreaEntryId == a.outAreaEntryId
                        )
                        newItems.splice(findIndex, 1)
                        return setItem(newItems)
                      }}
                      billType={rentType}
                      key={key}
                    />
                  </Fragment>
                )
              })}
            </div>
          )}
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: 'auto',
          mr: 0,
          justifyContent: 'flex-end',
        }}
      >
        <Grid container spacing={HORZ_SPACING} xs={12} pl={2} pr={0.5}>
          <Grid item={true} xs={12} md={md}>
            <CustomNumberField
              fullWidth
              name="deposite"
              value={deposite}
              label="Enter deposit"
              onChange={(event: any) => {
                setDeposite(event.target.value)
              }}
              maxLength={8}
            ></CustomNumberField>
          </Grid>
          <Grid item={true} xs={12} md={md}>
            <TextField
              size="small"
              name="vehicleNo"
              fullWidth
              value={vehicleNo}
              label="Enter Vehicle No"
              onChange={(event: any) => {
                if (event.target.value?.length < 11) {
                  setVehicleNo(event.target.value)
                }
              }}
            />
          </Grid>
          <Grid item={true} xs={12} md={md}>
            <FormControl sx={{ m: 0 }} fullWidth>
              <InputLabel id="select-time-helper-label">Select Time</InputLabel>
              <Select
                size="small"
                fullWidth
                labelId="select-time-helper-label"
                id="select-time"
                value={timeType}
                name="timeType"
                label="Select Time"
                onChange={(event: SelectChangeEvent) => {
                  setTimeType(event.target.value)
                }}
              >
                <MenuItem value={TimeTypeEnum.MORNING}>
                  Morning (The day is not counted)
                </MenuItem>
                <MenuItem value={TimeTypeEnum.EVENING}>Evening</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </AppDialog>
  )
}

export default SubmitItem
